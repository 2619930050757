<script>
import { mapActions } from "vuex";
export default {
  async created() {
    await this.fetchPanos();
  },
  methods: {
    ...mapActions(["fetchPanos"])
  }
};
</script>

<template>
  <div class="fix-container" id="app">
    <router-view/>
  </div>
</template>