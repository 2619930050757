<script>
import { getBooks, getBook } from "@/common/api";
import Vue from "vue";
const $ = window.jQuery;

export default {
  props: ["visible"],
  async created() {
    let { books, storeUrl } = await getBooks();
    this.books = books;
    this.storeUrl = storeUrl;
  },
  data() {
    return {
      books: [],
      storeUrl: undefined,
      selectedBook: undefined,
      flipbook: undefined
    };
  },
  methods: {
    close() {
      this.selectedBook = undefined;
      this.$emit("update:visible", false);
    },
    async chooseBook(bookId) {
      this.selectedBook = await getBook(bookId);
      Vue.nextTick(() => {
        this.flipbook = $(this.$refs.flipbook).turn({
          autoCenter: true,
          gradients: true,
          acceleration: true,
          duration: 1000,
          height: this.$refs.flipbook.clientWidth * 0.7,
          width: this.$refs.flipbook.clientWidth
        });
      });
    }
  }
};
</script>
<template>
  <div class="mask" v-if="visible">
    <div class="mask__shadow"></div>
    <div class="reading" v-if="selectedBook">
      <h3 class="reading__title">{{selectedBook.name}}</h3>
      <h6 class="reading__subtitle">试试翻动书页</h6>
      <div ref="flipbook" class="flipbook">
        <div
          class="flipbook__page"
          v-for="(page,index) in selectedBook.pages"
          :key="index"
          :class="{'hard':index===1||index===2||index===selectedBook.pageCount-1||index===selectedBook.pageCount}"
        >
          <img :src="page" alt>
        </div>
      </div>

      <i class="zd-close reading__close" @click="close"></i>
      <a :href="storeUrl" class="button reading__taobao">前往购买</a>
    </div>

    <div class="modal" v-else>
      <i class="zd-close modal__close" @click="close"></i>
      <div class="modal__content books">
        <div class="books__scroll">
          <div class="book" v-for="book in books" :key="book.name" @click="chooseBook(book.id)">
            <img class="book__image" :src="book.coverImage" alt>
            <div class="book__name">{{book.name}}</div>
          </div>
        </div>
      </div>
      <a :href="storeUrl" target="_blank" class="taobao-badge">
        <span class="mobile">前往购买</span>
        <span class="desktop">前往网上商店购买</span>
      </a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../var.scss";

.books {
  padding-top: 10px;
  width: 100%;
  height: 70vh;

  @include desktop {
    padding: 20px;
    box-sizing: border-box;
  }

  .books__scroll {
    display: flex;
    flex-wrap: wrap;
  }

  .book {
    padding: 0 5px 10px;
    box-sizing: border-box;
    width: 33.3%;
    text-align: center;
    cursor: pointer;

    @include desktop {
      width: 20%;
    }

    .book__image {
      width: 80%;
    }
    .book__name {
      width: 100%;
      font-size: 13px;
      color: $primary_color;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.taobao-badge {
  background: #0093cf;
  color: white;
  width: 140px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 20px auto 0;

  .desktop {
    display: none;
  }

  @include desktop {
    width: 40px;
    height: 154px;
    writing-mode: vertical-lr;
    position: absolute;
    right: 0;
    top: 180px;
    font-size: 14px;
    letter-spacing: 2px;

    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}

.reading {
  width: 100vw;
  height: 100vh;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .reading__title {
    font-size: 20px;
    color: $primary_color;
    text-align: center;
    margin: 30px 0 6px;
    font-weight: normal;
  }

  .reading__subtitle {
    opacity: 0.5;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1.12px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0 0 40px;
    font-weight: normal;
  }

  .reading__close {
    margin-top: 40px;
  }

  .reading__taobao {
    position: absolute;
    bottom: 40px;
    width: 150px;
    line-height: 40px;

    @include desktop {
      display: none;
    }
  }
}

.flipbook {
  margin-bottom: 20px;
  width: 100vw;
  @include desktop {
    width: 100vh;
  }

  .turn-page {
    background-color: white;
    vertical-align: middle;
  }

  .flipbook__page {
    background: white;
    img {
      width: 100%;
    }
  }
}
</style>
