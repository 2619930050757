<script>
const THREE = window.THREE;

export default {
  data() {
    return {
      camera: undefined,
      controls: undefined,
      scene: undefined,
      renderer: undefined
    };
  },
  mounted() {
    this.initCamera();
    this.initScene();
    this.initRenderer();
    this.resize();
    this.initControls();
    this.animate();
  },

  methods: {
    initCamera() {
      // 透视镜头
      let ratio = this.$el.clientWidth / this.$el.clientHeight;
      this.camera = new THREE.PerspectiveCamera(45, ratio, 1, 2000);
      this.camera.position.set(10, 20, 30);
    },
    initControls() {
      // 环视操作控制器
      let controls = (this.controls = new THREE.OrbitControls(
        this.camera,
        this.$el
      ));
      controls.enablePan = false;
      controls.target.set(0, 0, 0);
      controls.autoRotate = true;
      controls.maxDistance = 20; // 缩放限制
      controls.minDistance = 20; // 缩放限制
      controls.maxPolarAngle = Math.PI / 2.2; // 上下旋转限制
      controls.minPolarAngle = Math.PI / 4; // 上下旋转限制
      controls.update();
      this.controls = controls;
    },
    initScene() {
      let scene = (this.scene = new THREE.Scene());

      // 半球光（类似于环境光效果）
      let light;
      light = new THREE.HemisphereLight(0x888888, 0x888888, 0.5);
      light.position.set(0, 20, 0);
      scene.add(light);

      // 直射光，从上往下
      light = new THREE.DirectionalLight(0xb2d1ff);
      light.position.set(-100, 200, 100);
      light.castShadow = true;
      light.shadow.camera.top = 180;
      light.shadow.camera.bottom = -100;
      light.shadow.camera.left = -120;
      light.shadow.camera.right = 120;
      scene.add(light);

      var loader = new THREE.FBXLoader();
      loader.load(
        `https://zhendan-cdn.rebase.com.cn/model/ZDjianzu.fbx`,
        function(object) {
          object.scale.multiplyScalar(0.0015); // 导出模型尺寸调整
          scene.add(object);
        }
      );
    },

    initRenderer() {
      const renderer = (this.renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true
      }));
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      this.$el.appendChild(renderer.domElement);
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    resize() {
      let width = this.$el.clientWidth;
      let height = this.$el.clientHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }
  }
};
</script>
<template>
  <div class="building-model">
  </div>
</template>
<style lang="scss" scoped>
.building-model {
  cursor: -webkit-grab;
}
</style>

