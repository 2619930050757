import "./index.scss";
import Vue from "vue";
import Krpano from "vue-krpano";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(Krpano);
Vue.config.productionTip = false;

Vue.filter("qiniu", (url, args) => {
  if (url) {
    if (!url.startsWith("http")) {
      url = "https://zhendan-cdn.rebase.com.cn/" + url;
    }

    if (args) {
      url = url + "?imageView2/" + args;
    }

    return url;
  }
});

Vue.mixin({
  methods: {
    isWechat() {
      return /MicroMessenger/.test(window.navigator.userAgent);
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

window.addEventListener("contextmenu", function() {
  this.event.returnValue = false;
});
