<script>
import Service from "../components/Service";
import SceneBar from "../components/SceneBar";
import HotSpot from "../components/HotSpot";
import Comment from "../components/Comment";
import Books from "../components/Books";
import Brief from "../components/Brief";
import Bgm from "../components/Bgm";
import { mapState } from "vuex";

export default {
  components: {
    Service,
    HotSpot,
    SceneBar,
    Comment,
    Books,
    Brief,
    Bgm
  },
  data() {
    const vm = this;
    return {
      panoObj: undefined,
      hooks: {
        sceneChanged(scene) {
          vm.currentScene = scene;
        },
        showBooks() {
          vm.showBooks = true;
        },
        showHotspot(id) {
          vm.showHotspot = true;
          vm.hotspotId = id;
        },
        gotoPano(panoId) {
          vm.$router.push({
            name: vm.$route.name,
            params: { id: panoId }
          });
          vm.panoId = panoId;
          vm.currentScene = vm.currentPano.scenes[0].name;
        }
      },
      panoId: undefined,
      showService: false,
      showHotspot: false,
      showBrief: false,
      showBooks: false,
      sceneBarVisible: false,
      currentScene: undefined,
      hotspotId: undefined
    };
  },
  async created() {
    this.panoId = this.$route.params.id;
    let { scene } = this.$route.query;
    if (scene) {
      this.currentScene = scene;
    } else {
      this.currentScene = this.currentPano.scenes[0].name;
    }
  },
  methods: {
    gotoMenu() {
      this.$router.push({ name: "Menu" });
    },
    panoCreated(panoObj) {
      this.panoObj = panoObj;
      if (this.currentScene) {
        setTimeout(() => {
          this.panoObj.call(
            `zdLoadscene(${this.currentScene},null, MERGE, BLEND(0.8))`
          );
        }, 100);
      }
    },
    showSceneBar() {
      this.currentScene = this.panoObj.get("xml.scene");
      this.sceneBarVisible = true;
    },
    changeScene(scene) {
      this.panoObj.call(`loadscene(${scene},null, MERGE, BLEND(0.8))`);
      this.currentScene = scene;
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: { scene }
      });
    },
    hotspotPlayAudio() {
      this.$refs.bgm.stop();
    }
  },
  computed: {
    ...mapState(["panos"]),
    xmlPath() {
      let { id } = this.$route.params;
      return `/api/pano/${id}.xml`;
    },
    currentPano() {
      return this.panos.find(p => p.panoId === this.panoId);
    },
    sceneTitle() {
      if (this.currentPano) {
        let scene = this.currentPano.scenes.find(
          s => s.name === this.currentScene
        );
        if (scene) {
          return this.currentPano.floorNum + "F / " + scene.title;
        }
      }
    }
  }
};
</script>

<template>
  <div class="container" v-if="currentPano">
    <div class="header">
      <a href="http://www.auroramuseum.cn/" target="_blank">
        <img class="header__logo" src="../assets/images/logo.png" alt>
      </a>
      <div class="header__control">
        <!--
        <span class="label-with-icon header__service" @click="showService=true">
          <i></i> 服务
        </span>

        <span class="label-with-icon header__tour" @click="showBrief=true">
          <i></i> 参观
        </span>
        -->
        <bgm ref="bgm" :src="currentPano.bgm" v-if="currentPano.bgm&&currentPano.bgmEnabled"></bgm>
      </div>
    </div>
    <krpano
      :mwheel="true"
      class="pano"
      :xml="xmlPath"
      @panoCreated="panoCreated"
      :hooks="hooks"
      :lazyLoad="false"
      :mobilescale="1"
    ></krpano>
    <div class="footer">
      <div class="footer__map" @click="showSceneBar">
        <i></i>
        {{sceneTitle}}
      </div>
      <comment></comment>
    </div>

    <service :visible.sync="showService"></service>
    <hot-spot :visible.sync="showHotspot" :hotspot-id="hotspotId" @playAudio="hotspotPlayAudio"></hot-spot>
    <scene-bar
      :visible.sync="sceneBarVisible"
      :scenes="currentPano.scenes"
      :current-scene="currentScene"
      @sceneChange="changeScene"
    ></scene-bar>
    <books :visible.sync="showBooks"></books>
    <brief :visible.sync="showBrief"></brief>
  </div>
</template>


<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
}
.header {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 15px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;

  .header__logo {
    width: 135px;
    height: 41px;
  }

  .header__control {
    display: flex;
  }

  .label-with-icon {
    display: flex;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    line-height: 12px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    align-items: center;
    margin-right: 20px;
    cursor: pointer;

    i {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }

  .header__service i {
    background: url("../assets/images/service.png") center no-repeat;
    background-size: contain;
  }

  .header__tour i {
    background: url("../assets/images/compass.png") center no-repeat;
    background-size: contain;
  }
}

.footer {
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 15px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px;
  box-sizing: border-box;

  .footer__map {
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    line-height: 12px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    i {
      background: url("../assets/images/map.png") center no-repeat;
      background-size: contain;
      display: block;
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }
  }
}

.pano {
  width: 100vw;
  height: 100vh;
}
</style>


