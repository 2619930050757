<template>
  <div class="home">
    <krpano class="pano" xml="/pano/cover.xml" @panoCreated="panoCreated" :hooks="hooks"></krpano>
    <div v-if="!isEntered" class="title" @click="enter" ref="title">
      <router-link :to="{name:'Menu'}" class="title__skip">跳过</router-link>
      <img class="title__caption" src="../assets/images/title2.png">
      <img class="title__logo" src="../assets/images/logo2.png" alt>
    </div>
    <div class="brief" v-if="isEntered" @click="gotoMenu()">
      <img class="brief__enter" src="../assets/images/enter.png">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;

    return {
      isEntered: false,
      panoObj: undefined,
      hooks: {
        gotoMenu() {
          vm.gotoMenu();
        },
        entered() {
          vm.isEntered = true;
        }
      }
    };
  },
  methods: {
    panoCreated(panoObj) {
      this.panoObj = panoObj;
      setTimeout(() => {
        this.$refs.title.classList.add("title_fadeIn");
      }, 500);
    },
    enter() {
      if (!this.isEntered) {
        this.panoObj.call("doLittlePlanetRotate");
        this.$refs.title.classList.remove("title_fadeIn");
      }
    },
    gotoMenu() {
      this.$router.push({ name: "Menu" });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../var.scss";

@keyframes bref_fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pano {
  width: 100vw;
  height: 100vh;
}
.title {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .title__skip {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid white;
    color: white;
    border-radius: 2px;
    text-decoration: none;
    padding: 5px 10px;
    font-size: 14px;
    opacity: 0.9;
  }

  .title__caption {
    opacity: 0;
    transition: 1s;
    max-width: 340px;
    width: 80%;
  }

  .title__logo {
    width: 150px;
    opacity: 0;
    transition: 1s;
    position: absolute;
    bottom: 4%;
  }
}

.title_fadeIn {
  .title__caption {
    opacity: 1;
    transform: scale(1.1);
  }
  .title__logo {
    transform: translateY(-80%);
    opacity: 1;
  }
}

.brief {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(0, 147, 207, 0.5),
    rgba(0, 147, 207, 1)
  );
  animation: bref_fadeIn 0.5s both;
  animation-delay: 1s;
  padding: 25px 0;

  @include desktop {
    padding: 10px 0;
  }

  .brief__enter {
    display: block;
    width: 50%;

    @include desktop {
      width: 200px;
    }
  }
}
</style>

