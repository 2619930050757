<script>
export default {
  props: ["src"],
  data() {
    return {
      isPlaying: false
    };
  },
  methods: {
    toggle() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
        this.isPlaying = false;
      } else {
        this.$refs.audio.play();
        this.isPlaying = true;
      }
    },
    stop() {
      this.$refs.audio.pause();
      this.isPlaying = false;
    },
    onPaused() {
      this.isPlaying = false;
    },
    onPlay() {
      this.isPlaying = true;
    }
  }
};
</script>

<template>
  <div>
    <i class="icon" :class="{'icon_paused':!isPlaying,'icon_play':isPlaying}" @click="toggle()"></i>
    <audio ref="audio" :src="src|qiniu" autoplay @pause="onPaused" @play="onPlay"></audio>
  </div>
</template>
<style lang="scss" scoped>
.icon {
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
}

.icon_paused {
  background: url("../assets/images/music.png") center no-repeat;
  background-size: contain;
}

.icon_play {
  background: url("../assets/images/music2.png") center no-repeat;
  background-size: contain;
}
</style>

