import Vuex from "vuex";
import Vue from "vue";
import { getPanos } from "@/common/api";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: { panos: [] },
  mutations: {
    setPanos(state, panos) {
      state.panos = panos;
    }
  },
  actions: {
    async fetchPanos({ commit }) {
      let panos = await getPanos();
      commit("setPanos", panos);
    }
  }
});

export default store;
