<script>
export default {
  props: ["visible"],
  data() {
    return {
      current: undefined
    };
  },
  methods: {
    close() {
      this.current = undefined;
      this.$emit("update:visible", false);
    }
  }
};
</script>
<template>
  <div class="mask" v-if="visible">
    <div class="mask__shadow" @click="close()"></div>

    <ul class="menu" v-if="!current">
      <li class="menu__item" @click="current='guide'"><i class="info"></i>震旦博物馆参观须知</li>
      <li class="menu__item" @click="current='brief'"><i class="museum"></i>震旦博物馆环境概述</li>
      <li class="menu__item" @click="current='2f'"><i class="ceramics"></i>二楼-汉唐陶俑风华序言</li>
      <li class="menu__item" @click="current='3f'"><i class="jadeware"></i>三楼-历代玉器展厅序言</li>
      <li class="menu__item" @click="current='4f'"><i class="ciqi"></i>四楼-青花瓷展厅序言</li>
      <li class="menu__item" @click="current='5f'"><i class="research"></i>五楼-古器物学研究中心序言</li>
      <li class="menu__item" @click="current='6f'"><i class="buddhism"></i>六楼-佛教造像展厅序言</li>
    </ul>
    <div class="modal" v-else>
      <i class="zd-close modal__close" @click="close"></i>

      <div class="modal__content" v-if="current==='guide'">
        <h1 class="title">参观信息</h1>
        <div class="content" style="margin-bottom:30px;">
          <p>地址：上海市浦东新区陆家嘴富城路99号</p>
          <p>电话：021-5840-8899</p>
          <p>开馆时间：周二至周日 10:00-17:00/周五延长至21:00/周一休馆</p>
          <p>售票时间：周二至周日 9:30-16:00/周五延长至20:00</p>
          <p>票价：RMB60/优惠票价请依照现场票价</p>
        </div>

        <h1 class="title">交通信息</h1>
        <div class="content">
          <p>地铁搭乘地铁2号线，至【陆家嘴地铁站】2号口出站，步行约5分钟可到。</p>
          <p>公交搭乘81路、799路、993路于【陆家嘴环路名商路站】下车，步行约3分钟可到。</p>
        </div>
        <div class="navigate">
          <a class="navigate__button" href="http://www.google.cn/maps/place/%E9%9C%87%E6%97%A6%E5%8D%9A%E7%89%A9%E9%A6%86/@31.234851,121.4974286,17z/data=!4m5!3m4!1s0x35b270f08f96ff71:0x6c09d6057d5fb9e3!8m2!3d31.2347847!4d121.4992907">导航</a>
        </div>
      </div>

      <!-- 1f -->
      <div class="modal__content" v-if="current==='brief'">
        <h1 class="title">震旦博物馆环境概述</h1>
        <div class="content">
          <p>震旦博物馆整个馆体建筑分为六层：一楼包含博物馆商店、视听室和艺文厅，二楼、三楼、四楼和六楼的常设展览以汉唐陶俑、历代玉器、青花瓷器和佛教造像为主，五楼是古器物学研究中心的特展空间，不定期地将研究成果化为展览形式呈现给观众。</p>
          <p>The Aurora Museum building consists of six floors. The first floor features the museum gift shop, audio-visual room, and art hall. The second, third, fourth, and sixth floors are devoted to permanent exhibitions from the Museum collection of Han to Tang dynasty ceramic figurines, jades throughout the ages, blue-and-white porcelains, and Buddhist sculptures, respectively. The fifth floor is a special exhibition area for the Antiquities Research Center, where the Center’s studies are presented in a display format on an irregular basis for visitors.</p>
          <p>博物馆是建筑大师安藤忠雄在国内规划、设计并建造完成的第一座博物馆。在这里安藤先生秉持了他的一贯风格，以极简的几何造型让震旦博物馆独具特色。面向外滩的古建筑群，背靠高楼林立的陆家嘴国际金融中心，震旦博物馆在历史与现代的交界在线，架起了桥，连结了古今。</p>
          <p>The building was the first museum planned, designed, and constructed in China by the renowned architect Tadao Ando. Here, Ando stuck to his renowned style of minimalist geometric shapes to form the unique features of the Aurora Museum. Facing old buildings on the Bund, the Museum is backed by the glittering towers of the Lujiazui International Financial Center. Therefore, you may say that the Aurora Museum stands at the crossroads of history and modernity, serving as a bridge for past and present.</p>
        </div>
      </div>

      <!-- 2f -->
      <div class="modal__content" v-if="current==='2f'">
        <h1 class="title">汉唐陶俑风华</h1>
        <div class="content">
          <p>汉唐陶俑起源于东周，以小型简化的泥偶代替真人殉葬；秦始皇统一中国后，大修陵寝，在陪葬坑中安置了八干兵马俑及珍禽异兽俑等兵马俑与真人身形无异，为陶俑的烧制开创了写实性的风格。</p>
          <p>到了汉代，贵族富人竞相以陶俑随葬，虽然尺寸略小，但是造型生动，题材广泛，举凡武士、农夫、说唱艺人，乃至屋舍、车马都一一入列，这种「事死如事生」的厚葬风气，到唐代依然延续着。陶俑无不以写实的作风反映、复制当时的生活模式，延续逝者生前的荣景。</p>
          <p>汉唐陶俑的内容丰富，犹如一部汉唐社会发展的缩影，因此本展览着眼于生活性的题材，以实品展示和虚拟动画的方式，邀请观众进入时光隧道，领略汉唐人的生活百态。</p>
          <p>HAN/TANG POTTERY FIGURINES</p>
          <p>Pottery figurines for burial use first appeared in the Eastern Zhou period of ancient China. These were small pottery figures meant as substitutes for having real people buried together with the tomb occupant.</p>
          <p>By the Han dynasty, burial figurines had become a status symbol for the aristocracy and the wealthy. Although the figurines used during this period could in no way compete with the life-size terracotta figures of the former Qin dynasty, they fared well in comparison in terms of their sheer liveliness and thematic variety. There were, for example, warriors farmers, musicians and entertainers represented, as well as buildings, horses with or without chariots, and livestock, all created to give some impression of life in the tomb as a reflection of the life lived before death. This practice continued all the way until the Tang dynasty. These burial figurines were, to a figure, incredibly life-like, a faithful representation of how things were, a window onto the life lived by the deceased when alive.</p>
          <p>Tomb figurines from the Han to Tang were like snapshots of how life was lived in those times. We welcome you to embark on a journey back through time, to experience life during the old days</p>
        </div>
      </div>

      <!-- 3f -->
      <div class="modal__content" v-if="current==='3f'">
        <h1 class="title">历代玉器展厅</h1>
        <div class="content">
          <p>中国玉器的歴史源远流长，早在新石器时代中期，位于东北地区的兴隆湟文化与黄河中游的老官台文化、长江下游的河姆渡文化，已经开始用玉来制作工具或饰品。此后经过一、二干年的发展，在新石器时代晚期达到第一个高峰，如红山文化、良渚文化、龙山时期、齐家文化四个区系，正是当时的玉器中心。到了商代，随着统一王朝的出现，玉器的发展也从多元化的区域性风格转变为共同性的时代风格，而且经由传承与演变的模式，在西周、春秋、戦国、汉代、唐宋元明清等时代的兴替中，产生许多不同的器形与纹饰，构成一部国格殊异、连绵不的玉器发展史。本展即以时代的发展为主轴，精选新石器时代晚期至唐宋元明清的玉器精品，呈现历代玉器各擅胜场的风貌。</p>
          <p>Masterpieces of Jade through the Ages</p>
          <p>The Chinese tradition of carving jade is characterized by continuity and innovation over its immensely long history. As early as the mad Neolithic, seven or eight thousand years ago, people of the Xinlungwa culture of northeast China, of the Laoguantai culture along the middle stretch Yellow River. and of the Hemudu culture along the lower reaches of the Yangtze river, were carving special jade tools and jewelry. Well over a thousand years later, jade carving reached its first apogee in the late Neolithic period which saw the emergence of four major reginal production the Hongshan culture, Liangzhu culture, Longshan culture and Qijia culture In the Shang dynasty, distinctive regional styles diminished. and there emerged a period style. namely, the Shang style. which changed and developed throughout the succeeding millennia during western Zhou period. the Spring and Autumn and Warning States periods. the Han dynasty. and, then again. in the Tang, Song. Yuan, Min. and Qing dynasties in a nearly unbroken line of development it. Jade was carved into tools, weapons, ritual utensils daily utensils, ornaments, jewelry and other accessories in this exhibition, selected pieces are displayed chronologically, from the Neolithic period. through the Shang and Zhou periods, down to later dynasties, displaying the exquisite workmanship that characterized the working of jade for thousands of years</p>
        </div>
      </div>

      <!-- 4f -->
      <div class="modal__content" v-if="current==='4f'">
        <h1 class="title">青花瓷展厅</h1>
        <div class="content">
          <p>青花是以钴蓝作为纹饰着色剂的釉下彩瓷器，此一瓷类盛行于元明清三代，深入中国社会各个阶层，也随着陆上和海上贸易之路外销欧洲、亚洲及非洲等地。蓬勃的市场需求、广泛的使用层面及歴代皇室的主导，使青花瓷器呈现多彩多姿的风貌。元明清三代的青花瓷器品类非常丰富，常以多层次的纹饰布局瓶铺满全器。体型大者沉稳庄重，体型小者精巧秀丽；进口钴料发色浓艳，国产钴料则是淡雅、灰青兼而有之。以大笔描绘者笔触明快率真，小笔勾尽者清秀雅致，勾边平涂法色泽均匀稳定，侧笔涂抹法色阶分明，重笔点画法工整拘谨。除此之外，还融合了线刻、模印、塑贴等技法，与红、黄、绿等多种色彩，在蓝与白的对比基础上开发出多元化的装饰艺术。</p>
          <p>Blue-and-white porcelains</p>
          <p>Cobalt was used for the blue pigment in blue-and-white porcelains. The decor, in cobalt, was applied directly to the body of a piece, after which it was covered with a clear glaze and fired in a reducing atmosphere, resulting in what is now called “underglaze blue”. As the pigment was applied underneath the glaze, the decoration was permanent and could not fade. Such porcelains, prevalent during the Yuan dynasty as well as in the two succeeding dynasties, the Ming and the Qing, were not only widely used by the Chinese themselves, but also employed by them as commodities in trade. As a result, blue-and-white wares grew in popularity in Europe and southeast Asia, and found their way even to Africa. The demands of the market, and the patronage of the Yuan, Ming and Qing courts, ensured that in shape and design, blue-and-white porcelains became even more diverse Blue-and-white wares vary from large, grand pieces to small, elegant ones, with designs that are often rich and dense Imported cobalt produced designs of a deep, vibrant blue, often with discernible rust spots in places, whereas domestically mined cobalt produced designs of an even and consistent bluish-green hue, though at times with a purplish or greyish tint. The designs varied considerably, sometimes inspired by traditional fine brushwork, with close attention to detail, sometimes having a more impressionistic quality. As new decors developed, combining underglaze blue with incised, molded, appliqued, and impressed techniques, or with red, yellow, and green pigments, blue-and-white wares entered a new era of unprecedented beauty and sophistication</p>
        </div>
      </div>

      <!-- 5f -->
      <div class="modal__content" v-if="current==='5f'">
        <h1 class="title">古器物学研究中心</h1>
        <div class="content">
          <p>博物馆五楼是古器物学研究的中心。</p>
          <p>震旦博物馆倡导分解法的古器物学，就是以料、工、形、纹为四个切入点，通过分项研究，重塑一件器物制作时的时空背景，使它成为可以为各个学科借鉴参考的器物史料。同时也可以透过这件器物的整合研究，去了解历史、当时的文化及社会等发展脉络。</p>
          <p>The fifth floor of the Museum is its research center for antiquities.</p>
          <p>The guide to antiquities research at Aurora Museum is divided into the four approaches of materials, workmanship, forms, and decoration . Separated as such, the period background for the production of an object can be reconstructed, making it historical material for study in various branches of learning. At the same time, this integrated approach to a cultural relic allows us to better understand history, culture, and social developments at the time.</p>
        </div>
      </div>

      <!-- 6f -->
      <div class="modal__content" v-if="current==='6f'">
        <h1 class="title">佛教造像展厅</h1>
        <div class="content">
          <p>佛教与基督教、伊斯教，并称世界三大宗教。东汉时佛教从印度传来，在中国发展隆盛，一路朝着汉化的方向前进，经过两干年与中国传统文化的冲突与融合，渗入社会各个角落，成为中国历史上影响最大的外来宗教。宗教艺术的作品，是用图像说明宗教真理，佛教丰富的典籍史料，提供了源源不断的创作灵感及素材。佛教造像艺术歴代皆有创新，北朝的秀骨清像，隋唐的丰圆雄健，宋朝的雍容大度，明代的端严自在，各具特色。展厅中，北魏到明晚期的石雕、木刻、泥塑、铜铸等不同材质的造像，涵盖释迦佛、菩萨到罗汉等题材，观赏者不但可以欣赏雕塑艺术的美，更能体験人间净士的追寻。</p>
          <h3>Introduction to Buddhist Sculptures</h3>
          <p>Buddhism, which together with Christianity and Islam is known as one of the three great religions of the world, flourished in China, where it became the country’s most important imported religion. Buddhism emerged in China from India during the Eastern Han dynasty at the beginning of the Common Era. The religion underwent a process of gradual Sinicization, of conflict and assimilation with traditional Chinese culture over the intervening two millennia, a period in which the religion came to permeate every corner of Chinese society, Buddhist statuary evolved stylistically through the years, with each successive dynasty having its own characteristics. Thus, we have the delicate, clean lines of the Northern Dynasties period, the fuller, more robust depictions of the Sui and Tang dynasties. the magnanimity of the Song and the freer depictions of the Ming. In this exhibition area there are examples of statuary dating from the Northern Wei to the late Ming, in various media carved in stone and wood, fired in clay, and cast in bronze and covering a range of themes, including the Buddha, Bodhisattvas, and Aluohans. Visitors are invited to appreciate the artistic beauty of the pieces and to experience the spirituality that they emanate. the Buddhist paradise on Earth</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../var.scss";

.title {
  font-size: 18px;
  color: $primary_color;
  text-align: center;
  line-height: 14px;
  margin-bottom: 25px;
}

@keyframes rotateIn {
  0% {
    transform: rotateX(-60deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.menu {
  padding: 0;
  margin: 0;

  .menu__item {
    display: block;
    background: white;
    display: flex;
    font-size: 15px;
    color: #333333;
    letter-spacing: 1.43px;
    padding: 13px 15px;
    border-radius: 2px;
    margin-bottom: 15px;
    cursor: pointer;
    align-items: center;
    animation: rotateIn 0.2s ease;
    animation-fill-mode: both;

    @for $i from 1 through 7 {
      &:nth-of-type(#{$i}) {
        animation-delay: $i * 0.1s;
      }
    }

    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    i.info {
      background: url("../assets/images/info.png") center no-repeat;
      background-size: contain;
    }

    i.museum {
      background: url("../assets/images/museum.png") center no-repeat;
      background-size: contain;
    }

    i.ceramics {
      background: url("../assets/images/pingzi.png") center no-repeat;
      background-size: contain;
    }

    i.jadeware {
      background: url("../assets/images/jadeware.png") center no-repeat;
      background-size: contain;
    }

    i.ciqi {
      background: url("../assets/images/ciqi.png") center no-repeat;
      background-size: contain;
    }

    i.research {
      background: url("../assets/images/research.png") center no-repeat;
      background-size: contain;
    }

    i.buddhism {
      background: url("../assets/images/budd.png") center no-repeat;
      background-size: contain;
    }
  }
}

.content {
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  line-height: 22px;

  p {
    margin: 0 0 10px;
  }

  h3 {
    font-size: 1.1em;
    margin: 20px 0 10px;
  }
}

.navigate {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .navigate__button {
    border: 1px solid $primary_color;
    border-radius: 5px;
    font-size: 15px;
    color: $primary_color;
    letter-spacing: 3px;
    text-decoration: none;
    line-height: 34px;
    width: 118px;
    text-align: center;
    display: block;
  }
}
</style>
