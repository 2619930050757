import axios from "axios";

axios.defaults.responseType = "json";

export async function getPanos() {
  let rs = await axios.get("/api/panos");
  return rs.data;
}

export async function getCommentsCount() {
  let rs = await axios.get("/api/comments/count");
  return rs.data;
}

export async function getComments(page) {
  let rs = await axios.get("/api/comments", { params: { page } });
  return rs.data;
}

export async function postComment(text) {
  await axios.post("/api/comments", { text });
}

export async function like() {
  await axios.post("/api/likes");
}

export async function getHotspot(id) {
  let rs = await axios.get("/api/hotspots/" + id);
  return rs.data;
}

export async function getBooks() {
  let rs = await axios.get("/api/books");
  return rs.data;
}

export async function getBook(id) {
  let rs = await axios.get("/api/books/" + id);
  return rs.data;
}
