<script>
import { getCommentsCount, like, getComments, postComment } from "@/common/api";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Cookie from "js-cookie";

export default {
  components: {
    swiper,
    swiperSlide
  },
  props: ["visible"],
  data() {
    const vm = this;
    return {
      showComment: false,
      commentsCount: 0,
      likesCount: 0,
      isLiked: false,
      showLikeSuccess: false,
      mode: "review",
      commentText: undefined,
      currentIndex: 0,
      swiperOptions: {
        mousewheel: true,
        slidesPerView: window.outerWidth > 760 ? 3 : 1,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        spaceBetween: 20,
        on: {
          slideChange() {
            vm.currentIndex = this.activeIndex;
          },
          async reachEnd() {
            await vm.loadMore();
          }
        }
      },
      comments: {}
    };
  },
  async created() {
    let { commentsCount, likesCount } = await getCommentsCount();
    this.commentsCount = commentsCount;
    this.likesCount = likesCount;
    this.isLiked = !!window.localStorage.getItem("like");
  },
  async mounted() {
    this.comments = await getComments(1);
  },
  methods: {
    async like() {
      if (!this.isLiked) {
        await like();
        this.likesCount++;
        this.isLiked = true;
        window.localStorage.setItem("like", Date.now().toString());
        this.showLikeSuccess = true;
        setTimeout(() => {
          this.showLikeSuccess = false;
        }, 3000);
      }
    },
    async loadMore() {
      let { current, total } = this.comments.page;
      if (current < total) {
        let rs = await getComments(current + 1);
        this.comments.page = rs.page;
        this.comments.list = this.comments.list.concat(rs.list);
      }
    },
    checkAuth() {
      if (!Cookie.get("uid") && this.isWechat()) {
        const url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?" +
          "appid=wx4cf7a2b1bd8cb830&" +
          "redirect_uri=" +
          encodeURIComponent(window.location.origin + "/api/mplogin") +
          "&" +
          "response_type=code&" +
          "scope=snsapi_userinfo&" +
          "state=" +
          encodeURIComponent(window.location.href) +
          "#wechat_redirect";
        window.location = url;
      } else {
        this.mode = "post";
      }
    },
    async postComment() {
      if (this.commentText) {
        await postComment(this.commentText);
        this.commentText = undefined;
        alert("谢谢您的提交");
        this.mode = "review";
      } else {
        alert("说两句吧");
      }
    },
    defaultAvatar(commentId) {
      return `avatar/default/${(commentId % 12) + 1}.png`;
    },
    close() {
      this.showComment = false;
      this.mode = "review";
    }
  }
};
</script>
<template>
  <div>
    <div class="bar">
      <a class="bar__comment" @click="showComment=true">{{commentsCount}}</a>
      <a class="bar__like" :class="{'bar__like_active':isLiked}" @click="like()">{{likesCount}}</a>
    </div>

    <div class="mask" v-if="showComment">
      <div class="mask__shadow" @click="close()"></div>

      <div class="review fade-in" v-show="mode==='review'">
        <div class="indicator">{{currentIndex+1}}/{{comments.list.length}}</div>
        <swiper class="comments" :options="swiperOptions">
          <swiper-slide class="card" v-for="comment in comments.list" :key="comment.id">
            <div class="card__user userinfo" v-if="comment.user">
              <img class="userinfo__avatar" :src="comment.user.avatar" alt>
              <div class="userinfo__info">
                <p class="userinfo__nickname">{{comment.user.nickname}}</p>
                <p class="userinfo__area">{{comment.user.area}}</p>
              </div>
            </div>
            <div v-else class="card__user userinfo">
              <img class="userinfo__avatar" :src="defaultAvatar(comment.id)|qiniu" alt>
              <div class="userinfo__info">
                <p class="userinfo__nickname">游客</p>
              </div>
            </div>
            <div class="card__text">{{comment.text}}</div>
          </swiper-slide>
        </swiper>
        <button class="button post-button" @click="checkAuth()">发表评论</button>
      </div>
      <div class="post fade-in" v-show="mode==='post'">
        <textarea
          autofocus
          placeholder="随便说两句吧"
          class="post__textarea"
          v-model="commentText"
          cols="30"
          rows="10"
        ></textarea>
        <div class="post__buttons">
          <button class="post__cancel" @click="mode='review'">取消</button>
          <button class="post__ok" @click="postComment()">确认</button>
        </div>
      </div>
    </div>
    <div class="like-success" v-if="showLikeSuccess">
      <span>点赞成功</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../var.scss";

.bar {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 14px;
  cursor: pointer;

  .bar__comment::before,
  .bar__like::before {
    display: inline-block;
    width: 18px;
    height: 18px;
    content: "";
    vertical-align: sub;
  }

  .bar__like_active {
    color: $primary_color;
  }

  .bar__comment::before {
    background: url("../assets/images/comment.png") center no-repeat;
    background-size: contain;
  }

  .bar__like::before {
    background: url("../assets/images/likes.png") center no-repeat;
    background-size: contain;
  }

  .bar__like_active::before {
    background: url("../assets/images/likes2.png") center no-repeat;
    background-size: contain;
  }

  .bar__like {
    margin-left: 10px;
  }
}

.review {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .indicator {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1.12px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }

  .comments {
    margin-bottom: 30px;
    width: 100%;
  }

  .post-button {
    line-height: 40px;
    width: 180px;
  }
}

.post {
  background: white;
  width: 70%;
  border-radius: 2px;

  .post__textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border: none;
  }

  .post__buttons {
    display: flex;
    height: 40px;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    button {
      font-size: 14px;
      letter-spacing: 1.67px;
      text-align: center;
      width: 50%;
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;
    }

    button.post__cancel {
      color: #9b9b9b;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
    }
    button.post__ok {
      color: #0093cf;
    }
  }
}

.card {
  background: white;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;

  .card__user {
    margin-bottom: 15px;
  }

  .card__text {
    font-size: 14px;
    color: #333333;
    letter-spacing: 1.12px;
    line-height: 22px;
    min-height: 4em;
  }
}

.userinfo {
  display: flex;
  .userinfo__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .userinfo__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
  }

  .userinfo__nickname {
    font-size: 15px;
    color: #333333;
    letter-spacing: 1.2px;
    margin: 0;
    line-height: 21px;
  }

  .userinfo__area {
    font-size: 12px;
    color: #9b9b9b;
    letter-spacing: 0.96px;
    margin: 0;
  }
}

.like-success {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 6px;
  font-size: 15px;
  letter-spacing: 1.79px;
  text-align: center;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  padding: 20px 15px;
  animation: likeSuccessFadeIn 0.3s ease;
}

@keyframes likeSuccessFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
