import Vue from 'vue'
import Router from 'vue-router'
import Cover from './views/Cover.vue'
import Menu from "./views/Menu.vue"
import Tour from "./views/Tour.vue"

Vue.use(Router)

export default new Router({
  routes: [{
    path: '/',
    name: 'Cover',
    component: Cover
  }, {
    path: "/menu",
    name: "Menu",
    component: Menu
  }, {
    path: "/tour/:id",
    name: "Tour",
    component: Tour
  }]
})
