<script>
import { mapState } from "vuex";
import LayerModel from "../components/LayerModel";
import BuildingModel from "../components/BuildingModel";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Comment from "@/components/Comment";

export default {
  components: {
    Comment,
    swiper,
    swiperSlide,
    BuildingModel,
    LayerModel
  },
  data() {
    const vm = this;
    return {
      showSelector: false,
      currentFloorNum: 0,
      swiperIndex: 0,
      swiperOptions: {
        mousewheel: true,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 3,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          slideChange() {
            vm.swiperIndex = this.activeIndex;
          }
        }
      }
    };
  },
  created() {
    let { panoId } = this.$route.query;
    if (panoId) {
      let match = this.floors.find(floor => floor.panoId === panoId);
      if (match) {
        this.currentFloorNum = match.floorNum;
      }
    }
  },
  methods: {
    changeFloor(floor) {
      if (floor.floorNum === 0) {
        this.showSelector = false;
      }
      this.currentFloorNum = floor.floorNum;
      this.activeIndex = 0;
      this.$router.replace({
        name: this.$route.name,
        query: { panoId: floor.panoId }
      });
    },
    gotoTour() {
      this.$router.push({
        name: "Tour",
        params: { id: this.currentFloor.panoId },
        query: { scene: this.currentScene.name }
      });
    }
  },
  computed: {
    ...mapState(["panos"]),
    floors() {
      return [
        {
          brief:
            "博物馆是建筑大师安藤忠雄在国内规划、设计并建造完成的第一座博物馆。在这里安藤先生秉持了他的一贯风格，以极简的几何造型让震旦博物馆独具特色。面向外滩的古建筑群，背靠高楼林立的陆家嘴国际金融中心，震旦博物馆在历史与现代的交界上，架起沟通的桥梁，连接古今。",
          floorNum: 0,
          scenes: []
        },
        ...this.panos
      ];
    },
    swiper() {
      return this.$refs.sceneSwiper.swiper;
    },
    currentFloor() {
      return this.floors[this.currentFloorNum];
    },
    currentFloorScenes() {
      return this.currentFloor.scenes.filter(s => !s.hide);
    },
    currentScene() {
      return this.currentFloorScenes[this.swiperIndex];
    }
  }
};
</script>
<template>
  <div class="container">
    <div class="header">
      <a target="_blank" href="http://www.auroramuseum.cn/">
        <img class="header__logo" src="../assets/images/logo.png" alt>
      </a>
      <comment></comment>
    </div>

    <building-model v-if="currentFloorNum===0" class="model-3d"></building-model>
    <layer-model v-else class="model-3d" :model-id="`ZDpingcheng0${currentFloorNum}`"></layer-model>

    <div class="main" :class="{'main_onlychild':currentFloorNum===0}">
      <div class="selector" :class="{'visible':showSelector}" v-if="currentFloorScenes.length>0">
        <div class="title">
          <h4 class="title__text" v-if="currentScene">{{currentScene.title}}</h4>
          <i class="title__arrow"></i>
        </div>
        <swiper class="swiper" :options="swiperOptions" ref="sceneSwiper">
          <swiper-slide class="scene" v-for="(scene) in currentFloorScenes" :key="scene.name">
            <div @click="gotoTour()">
              <img class="scene__image" :src="scene.thumburl|qiniu('1/w/160/h/90')">
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <div class="brief menu-brief" :class="{'visible':!showSelector}" v-html="currentFloor.brief"></div>
      <div class="enter" v-if="currentFloorNum>0">
        <button class="button" @click="gotoTour()">进入楼层</button>
      </div>
      <div
        class="switch"
        v-if="currentFloorNum>0"
        :style="{'text-align':showSelector?'right':'left'}"
      >
        <span v-if="showSelector" @click="showSelector=false">查看简介 →</span>
        <span v-else @click="showSelector=true">→ 进入楼层</span>
      </div>
    </div>

    <div class="circle-buttons">
      <a
        class="circle-button"
        @click="changeFloor(floor)"
        v-for="floor in floors"
        :key="floor.floorNum"
        :class="{'circle-button_active':currentFloorNum===floor.floorNum}"
      >
        <span v-if="floor.floorNum===0">建筑</span>
        <span v-else>{{floor.floorNum}}F</span>
      </a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../var.scss";

.container {
  display: flex;
  // justify-content: ;
  width: 100vw;
  height: 100vh;
  background-image: -webkit-radial-gradient(
    center,
    rgba(167, 188, 255, 0.36),
    rgba(167, 188, 255, 0.18),
    rgba(0, 0, 0, 1)
  );
  background-color: black;

  flex-direction: column;
}
.model-3d {
  width: 100%;
  height: 35vh;
}

.main {
  height: 35vh;
}

@include desktop {
  .container {
    align-items: center;
    flex-direction: row;

    .model-3d {
      width: 50vw;
      height: 35vw;
    }
    .main {
      width: 40vw;
      height: 500px;
      display: flex;
      flex-direction: column;
      position: relative;

      .enter {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .main_onlychild {
      justify-content: center;
    }

    .circle-buttons {
      width: 10vw;
      height: 50vh;
    }
  }
}

.header {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  a {
    z-index: 1000;
    .header__logo {
      width: 135px;
      height: 40px;
    }
  }

  @include desktop {
    position: absolute;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px;

    .header__logo {
      width: 165px;
      height: 50px;
    }
  }
}

.selector {
  display: none;
  @include desktop {
    display: block;
    margin-bottom: 30px;
  }
  &.visible {
    display: block;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;

    .title__text {
      font-weight: normal;
      color: white;
      text-align: center;
      margin: 0 0 8px;
      font-size: 18px;
      text-align: center;
      text-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
    }

    @keyframes arrow-float {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(8px);
      }
      100% {
        transform: translateY(0);
      }
    }

    .title__arrow {
      width: 18px;
      height: 18px;
      display: block;
      background: url("../assets/images/arrow.png") center no-repeat;
      background-size: contain;
      animation: arrow-float 1.5s ease infinite;
      margin-bottom: 10px;
    }
  }

  .swiper {
    width: 100%;
    flex-direction: row;

    .swiper-button-next {
      transform: scale(0.5);
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
      right: 0;
    }
    .swiper-button-prev {
      transform: scale(0.5);
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
      left: 0;
    }
  }

  .scene {
    .scene__image {
      width: 100%;
      cursor: pointer;
    }
  }
}

.brief {
  font-size: 14px;
  color: #ffffff;
  text-align: justify;
  line-height: 25px;
  overflow-y: scroll;
  height: 80%;
  padding: 0 25px;
  -webkit-overflow-scrolling: touch;
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 80%, transparent);
  &::-webkit-scrollbar {
    width: 0;
  }

  display: none;

  @include desktop {
    display: block;
    overflow-y: inherit;
    height: auto;
    mask-image: none;
  }

  &.visible {
    display: block;
  }
}

.enter {
  display: none;
  padding: 0 25px;
  @include desktop {
    display: flex;
    justify-content: flex-end;
  }

  button {
    width: 150px;
    line-height: 40px;
    cursor: pointer;
  }
}

.switch {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1.5px;
  text-shadow: 0 0 6px #0093cf;
  padding: 10px 25px 0;

  @include desktop {
    display: none;
  }

  span {
    cursor: pointer;
  }
}

.circle-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;

  @include desktop {
    flex-direction: column;
  }

  .circle-button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: block;
    font-size: 17px;
    color: #ffffff;
    border: 1px solid white;
    letter-spacing: 2px;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    transition: 0.3s;
  }

  .circle-button_active {
    background-color: $primary_color;
    border: 1px solid $primary_color;
  }
}
</style>

<style lang="scss">
.menu-brief {
  & p {
    margin: 0 0 5px;
    text-indent: 2em;
  }
  & p:last-of-type {
    margin-bottom: 50px;
  }
}
</style>



