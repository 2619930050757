<script>
export default {
  props: ["visible", "scenes", "currentScene"],
  methods: {
    close() {
      this.$refs.bar.classList.remove("bar_fadeIn");
      setTimeout(() => {
        this.$emit("update:visible", false);
      }, 300);
    },
    changeScene(scene) {
      this.$emit("sceneChange", scene);
    },
    gotoMenu() {
      let { id } = this.$route.params;
      this.$router.push({ name: "Menu", query: { panoId: id } });
    }
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.bar.classList.add("bar_fadeIn");
        }, 100);
      }
    }
  }
};
</script>
<template>
  <div class="mask" v-show="visible">
    <div class="mask__shadow" @click="close()" @touchstart="close()"></div>
    <div class="bar" ref="bar">
      <div class="bar__scroll" :style="{width:`${scenes.length*175}px`}">
        <div class="bar__select" @click="gotoMenu">
          <div> 《 </div>
          <div>
            楼<br/>层<br/>选<br/>择
          </div>
          </div>
          <div v-for="scene in scenes" :key="scene.name" class="bar__item scene" :style="{'background-image':`url(${scene.thumburl})`}" @click.stop="changeScene(scene.name)">
            <div class="scene_active" v-if="scene.name===currentScene"></div>
            <div class="scene__title">{{scene.title}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../var.scss";

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  z-index: 1000;
}

.bar {
  width: 100%;
  position: absolute;
  bottom: 15%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.4);
  padding: 8px 0;
  opacity: 0;
  transition: 0.3s;
  transform: translateY(20px);
  z-index: 1100;

  &::-webkit-scrollbar {
    display: none;
  }

  .bar__scroll {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }

  .bar__select {
    font-size: 13px;
    color: white;
    letter-spacing: 1.53px;
    text-align: center;
    line-height: 16px;
    text-shadow: 0 0.5px 0 rgba(255, 255, 255, 0.5);
    background-image: linear-gradient(
      -46deg,
      $primary_color 0%,
      #40aad5 51%,
      $primary_color 100%
    );
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50px;
    margin-right: 15px;
    cursor: pointer;
  }
}

.bar_fadeIn {
  transform: translateY(0);
  opacity: 1;
}

.scene {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
  border-radius: 4px;
  overflow: hidden;
  width: 160px;
  height: 90px;
  position: relative;
  cursor: pointer;

  .scene__title {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: right;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 3px;
    box-sizing: border-box;
  }
}

.scene_active {
  width: 100%;
  height: 100%;
  z-index: 1000;
  border: 3px solid $primary_color;
  box-sizing: border-box;
  position: absolute;
}
</style>
