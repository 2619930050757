<script>
export default {
  props: ["visible"],
  data() {
    return {
      contentId: undefined
    };
  },
  methods: {
    close() {
      this.contentId = undefined;
      this.$emit("update:visible", false);
    }
  }
};
</script>
<template>
  <div class="mask" v-if="visible">
    <div class="mask__shadow" @click="close()"></div>

    <div class="grid fade-in" v-if="contentId===undefined">
      <div class="grid__item headphone" @click="contentId='audio_guide'">
        <i></i>
        <label for="">语音导览</label>
      </div>
      <div class="grid__item heart" @click="contentId='public_service'">
        <i></i>
        <label for="">公共服务</label>
      </div>
      <div class="grid__item pot" @click="contentId='art_store'">
        <i></i>
        <label for="">博物馆商店</label>
      </div>
      <div class="grid__item coffee" @click="contentId='cafe'">
        <i></i>
        <label for="">咖啡厅</label>
      </div>
      <div class="grid__item ticket" @click="contentId='ticket'">
        <i></i>
        <label for="">票务信息</label>
      </div>
      <div class="grid__item park" @click="contentId='park'">
        <i></i>
        <label for="">停车信息</label>
      </div>
    </div>

    <div class="modal" v-else>
      <i class="zd-close modal__close" @click="close"></i>
      <div class="content" v-if="contentId==='art_store'">
        <h3 class="content__header pot">博物馆商店</h3>
        <p>
          博物馆商店内贩售本馆藏品图录与专书、各式博物馆文创产品以及国内外知名艺术家与设计师的经典工艺作品
        </p>
      </div>
      <div class="content" v-else-if="contentId==='audio_guide'">
        <h3 class="content__header headphone">语音导览</h3>
        <p>
          馆内提供智能与人工两类导览服务。智能导览设备限当日使用，租金20元/台，需以个人身份证件租借。人工导览服务由专职讲解员与志愿者讲解员定期为参观者提供，具体事项请留意网站公示或咨询服务台。
        </p>
      </div>
      <div class="content" v-else-if="contentId==='cafe'">
        <h3 class="content__header coffee">咖啡厅</h3>
        <p>
          一层A Café 1与五层A Café 5，供应各式咖啡茶饮与西式简餐，在参观之余另为参观者提供一方慢活休憩之所
        </p>
      </div>
      <div class="content" v-else-if="contentId==='public_service'">
        <h3 class="content__header heart">公共服务</h3>
        <p>
          一层服务台可免费索取参观活动资料，并提供信息咨询、免费寄存等服务。馆内各层另可根据参观者需求提供相应的帮助与个性化服务。
        </p>
      </div>
      <div class="content" v-else-if="contentId==='ticket'">
        <h3 class="content__header ticket">票务信息</h3>
        <p>全 票 人民币60元/次</p>
        <p>优惠票 人民币30元/次 <br />包含（学生、现役军人、残障人士、离休干部、60岁以上老人）</p>
        <p>免 票 烈士家属、残障军人、未达130cm儿童、70岁以上老人</p>
        <p>备注：优惠票及免票人员须至服务台出示相关证件</p>
      </div>
      <div class="content" v-else-if="contentId==='park'">
        <h3 class="content__header park">停车信息</h3>
        <p>震旦博物馆设有地下停车场，总面积3000平方米。</p>
        <p>收费标准：10元/小时 不满一小时，按一小时计费，每日收费不高于80元/天。</p>
      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
@import "../var.scss";

$item_size: 90px;
$item_gutter: 20px;

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  position: relative;
  top: -10%;
  width: $item_size * 3 + $item_gutter * 2;
  height: $item_size * 2 + $item_gutter;

  .grid__item {
    width: $item_size;
    height: $item_size;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background: white;
    border-radius: 2px;

    i {
      width: 60px;
      height: 60px;
      display: block;
      margin-bottom: 5px;
    }

    label {
      display: block;
      font-size: 14px;
      color: #404040;
      text-align: center;
      line-height: 14px;
    }
  }

  @include desktop {
    flex-wrap: nowrap;
    width: $item_size * 6 + $item_gutter * 5;
    height: $item_size;
  }

  .grid__item.headphone i {
    background: url("../assets/images/headphone.png") center no-repeat;
    background-size: contain;
  }

  .grid__item.heart i {
    background: url("../assets/images/heart.png") center no-repeat;
    background-size: contain;
  }
  .grid__item.pot i {
    background: url("../assets/images/pot.png") center no-repeat;
    background-size: contain;
  }
  .grid__item.coffee i {
    background: url("../assets/images/coffee.png") center no-repeat;
    background-size: contain;
  }
  .grid__item.ticket i {
    background: url("../assets/images/ticket.png") center no-repeat;
    background-size: contain;
  }
  .grid__item.park i {
    background: url("../assets/images/park.png") center no-repeat;
    background-size: contain;
  }
}

.content {
  color: #333;

  .content__header {
    font-size: 18px;
    color: $primary_color;
    text-align: center;
    line-height: 14px;
    margin: 0 0 20px;
    position: relative;

    &::before {
      position: relative;
      vertical-align: sub;
      content: " ";
      width: 25px;
      height: 25px;
      display: inline-block;
      margin-right: 10px;
      margin-top: 0px;
      top: 3px;
    }

    &.headphone::before {
      background: url("../assets/images/headphone.png") center no-repeat;
      background-size: contain;
    }

    &.heart::before {
      background: url("../assets/images/heart.png") center no-repeat;
      background-size: contain;
    }

    &.pot::before {
      background: url("../assets/images/pot.png") center no-repeat;
      background-size: contain;
    }

    &.coffee::before {
      background: url("../assets/images/coffee.png") center no-repeat;
      background-size: contain;
    }

    &.ticket::before {
      background: url("../assets/images/ticket.png") center no-repeat;
      background-size: contain;
    }
    &.park::before {
      background: url("../assets/images/park.png") center no-repeat;
      background-size: contain;
    }
  }

  p {
    font-size: 14px;
    color: #000000;
    letter-spacing: 1px;
    line-height: 22px;
  }
}
</style>


