<script>
import { getHotspot } from "../common/api";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import VueGallery from "vue-gallery";

export default {
  components: {
    swiper,
    swiperSlide,
    VueGallery
  },
  props: ["visible", "hotspotId"],
  data() {
    const vm = this;
    return {
      galleryIndex: undefined,
      vrobj: undefined,
      hotspot: undefined,
      isAudioPlaying: false,
      currentImageIndex: 0,
      swiperOptions: {
        mousewheel: true,
        autoplay: true,
        centeredSlides: true,
        on: {
          slideChange() {
            vm.currentImageIndex = this.activeIndex;
          }
        }
      }
    };
  },
  methods: {
    async fetchData() {
      this.hotspot = undefined;
      this.hotspot = await getHotspot(this.hotspotId);
    },
    close() {
      this.currentImageIndex = 0;
      this.$emit("update:visible", false);
    },
    playAudio() {
      let audio = this.$refs.audio;
      if (this.isAudioPlaying) {
        audio.pause();
        this.isAudioPlaying = false;
      } else {
        audio.play();
        this.isAudioPlaying = true;
        this.$emit("playAudio");
      }
    },
    audioPause() {
      this.isAudioPlaying = false;
    },
    showVRObj(vrobj) {
      this.vrobj = vrobj;
    },
    imageSlideTo(index) {
      this.$refs.swiper.swiper.slideTo(index);
    }
  },
  computed: {
    textArr() {
      return this.hotspot.text
        .split("\n")
        .map(t => "<p>" + t + "</p>")
        .join("");
    }
  },
  watch: {
    hotspotId: "fetchData"
  }
};
</script>
<template>
  <div class="mask" v-if="visible&&hotspot">
    <div class="mask__shadow" @click="close()" @touch="close()"></div>

    <div class="modal">
      <div class="zd-close modal__close" @click="close"></div>

      <div class="modal__content">
        <div class="images">
          <swiper :options="swiperOptions" ref="swiper">
            <swiper-slide v-for="(image,idx) in hotspot.images" :key="image" class="images__item">
              <div
                class="images__image"
                :style="{'background-image':`url(${image})`}"
                @click="galleryIndex=idx"
              ></div>
            </swiper-slide>
          </swiper>
          <div class="thumbs">
            <div
              v-for="(image,index) in hotspot.images"
              :key="image"
              class="thumbs__image"
              :class="{'thumbs__image_active':index===currentImageIndex}"
              :style="{'background-image':`url(${image})`}"
              @click="imageSlideTo(index)"
            ></div>
          </div>
        </div>

        <div class="info">
          <h3 class="title">{{hotspot.title}}</h3>
          <div class="buttons">
            <template v-if="hotspot.audio">
              <audio ref="audio" :src="hotspot.audio" @pause="audioPause" @ended="audioPause"></audio>
              <button class="buttons__play" @click="playAudio()">
                <i :class="isAudioPlaying?'stop':'play'"></i>
                讲解
              </button>
            </template>
            <button class="buttons__view3d" v-if="hotspot.vrobj" @click="showVRObj(hotspot.vrobj)">
              <i class="eye"></i>
              3D
            </button>
          </div>
          <div class="hotspot-text" v-html="textArr"></div>
        </div>
      </div>
    </div>

    <div class="vrobj" v-if="vrobj">
      <iframe class="vrobj__iframe" :src="vrobj" frameborder="0"></iframe>
      <i class="zd-close vrobj__close" @click="vrobj=undefined"></i>
    </div>

    <vue-gallery
      :images="hotspot.images"
      :index="galleryIndex"
      @close="galleryIndex=undefined"
      :options="{'continuous':false}"
    ></vue-gallery>
  </div>
</template>

<style lang="scss" scoped>
@import "../var.scss";

.mask {
  display: flex;
  flex-direction: column;
}

@include desktop {
  .modal {
    max-width: 800px;

    .modal__content {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-around;
      overflow: initial;

      .info {
        width: 55%;
        max-height: 50vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .images {
        width: 40%;
      }
    }
  }
}

.images {
  .images__item {
    display: flex;
    justify-content: center;
  }
  .images__image {
    cursor: pointer;
    width: 100%;
    height: 170px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include desktop {
      height: 250px;
    }
  }
}

.thumbs {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .thumbs__image {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 6px;
    margin-bottom: 6px;
    cursor: pointer;

    @include desktop {
      width: 66px;
      height: 66px;
    }
  }

  .thumbs__image_active {
    box-shadow: 0 0 0 2px $primary_color inset;
  }
}

.title {
  font-size: 18px;
  color: $primary_color;
  margin-top: 0;
  margin-bottom: 15px;

  @include desktop {
    font-size: 32px;
  }
}

.buttons {
  display: flex;

  button {
    font-size: 13px;
    color: $primary_color;
    letter-spacing: 0.93px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary_color;
    border-radius: 2px;
    background: transparent;
    margin-right: 20px;
    cursor: pointer;
    width: 90px;

    i {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 5px;
    }

    i.play {
      background: url("../assets/images/play.png") center no-repeat;
      background-size: contain;
    }

    i.stop {
      background: url("../assets/images/stop.png") center no-repeat;
      background-size: contain;
    }

    i.eye {
      background: url("../assets/images/eye.png") center no-repeat;
      background-size: contain;
    }
  }
}

.vrobj {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  z-index: 1000;

  .vrobj__iframe {
    width: 100%;
    height: 100%;
  }

  .vrobj__close {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<style lang="scss">
.hotspot-text {
  margin-top: 10px;
  p {
    font-size: 14px;
    color: #333333;
    text-align: justify;
    line-height: 24px;
    margin: 5px 0;
  }
}
</style>

