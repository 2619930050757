<script>
const THREE = window.THREE;

export default {
  props: {
    modelId: { type: String, required: true }
  },
  data() {
    return {
      camera: undefined,
      controls: undefined,
      scene: undefined,
      renderer: undefined,
      labelRenderer: undefined,
      labels: {}
    };
  },
  mounted() {
    setTimeout(() => {
      this.initCamera();
      this.initScene();
      this.initRenderer();
      this.resize();
      this.initControls();
      this.animate();

      window.addEventListener("resize", this.resize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {
    initCamera() {
      // 透视镜头
      let ratio = this.$el.clientWidth / this.$el.clientHeight;
      this.camera = new THREE.PerspectiveCamera(45, ratio, 1, 2000);
      // this.camera.position.set(100, 200, 300);
    },
    initControls() {
      // 环视操作控制器
      let controls = (this.controls = new THREE.OrbitControls(
        this.camera,
        this.$el
      ));
      controls.enablePan = false;
      controls.target.set(0, -40, 0);
      controls.autoRotate = true;
      controls.maxDistance = 350; // 缩放限制
      controls.minDistance = 350; // 缩放限制
      controls.maxPolarAngle = Math.PI / 3.5; // 上下旋转限制
      controls.minPolarAngle = Math.PI / 3.5; // 上下旋转限制
      controls.update();
      this.controls = controls;
    },
    initScene() {
      let scene = (this.scene = new THREE.Scene());

      // 半球光（类似于环境光效果）
      let light = new THREE.HemisphereLight(0xffffff, 0x444444);
      light.position.set(0, 200, 0);
      scene.add(light);

      var loader = new THREE.FBXLoader();
      let modelUrl = `https://zhendan-cdn.rebase.com.cn/model/${
        this.modelId
      }.fbx`;
      loader.load(modelUrl, function(object) {
        object.scale.multiplyScalar(0.005); // 导出模型尺寸调整
        scene.add(object);
      });
    },
    initRenderer() {
      let width = this.$el.clientWidth;
      let height = this.$el.clientHeight;

      const renderer = (this.renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true
      }));
      renderer.setPixelRatio(window.devicePixelRatio);

      renderer.setSize(width, height);
      renderer.shadowMap.enabled = true;
      this.$el.appendChild(renderer.domElement);

      const labelRenderer = (this.labelRenderer = new THREE.CSS2DRenderer());
      labelRenderer.setSize(width, height);
      labelRenderer.domElement.style.position = "absolute";
      labelRenderer.domElement.style.top = 0;
      this.$el.appendChild(labelRenderer.domElement);
    },

    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      this.labelRenderer.render(this.scene, this.camera);
    },

    resize() {
      let width = this.$el.clientWidth;
      let height = this.$el.clientHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }
  },
  watch: {
    modelId() {
      for (let i in this.labels) {
        this.scene.remove(this.labels[i]);
      }
      this.initScene();
    }
  }
};
</script>
<template>
  <div class="layer-model"></div>
</template>

<style lang="scss" scoped>
.layer-model {
  position: relative;
  cursor: -webkit-grab;
}
</style>

<style lang="scss">
@import "../var.scss";
.layer-label {
  font-weight: bold;
  color: #333333;
  font-size: 20px;
}

.layer-label_active {
  color: #ffffff;
  font-size: 30px;
  background: $primary_color;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}
</style>